<template>
  <div id="add-edit-article">
    <div class="wrapper">
      <!-- section dashboard -->
      <section
        id="return-section"
        class="section section-secondary jpadding jpadding-15"
      >
        <div class="jcard jcard-main jcard-nohover">
          <div class="card-title text-center">
            <h3 v-if="!editMode">New blog article</h3>
            <h3 v-else>Edit blog article</h3>
          </div>

          <div class="accordion" role="tablist">
            <!----------------------- 1 ----------------------->
            <div class="inner-jcard jtitle-w-btns mb-1">
              <h5
                @click="showPictureInput = false"
                v-b-toggle.accordion-1
                class="articles-title"
              >
                1. General info
              </h5>
              <div class="side-btns">
                <h5
                  v-if="
                    validInput(article.title) &&
                      validLinkName(article.link_name)
                  "
                  key="checkedGeneral"
                  class="text-blue"
                >
                  <i class="fa fa-check-circle"></i>
                </h5>
                <h5 v-else key="uncheckedGeneral">
                  <i class="fal fa-exclamation-circle"></i>
                </h5>
              </div>
            </div>
            <b-collapse
              id="accordion-1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <!----------------------- content start ----------------------->
                <div class="form-group">
                  <label for="title"
                    ><i class="fad fa-newspaper"></i> Title</label
                  >
                  <input
                    type="text"
                    v-bind:class="{
                      'form-control': true,
                      'is-invalid': !validInput(article.title) && bluredTitle,
                    }"
                    v-on:blur="bluredTitle = true"
                    v-model="article.title"
                  />
                  <div class="invalid-feedback">
                    Please enter a title for this article.
                  </div>
                </div>

                <div class="form-group">
                  <label for="title"
                    ><i class="fad fa-link"></i> Link name</label
                  >
                  <input
                    type="text"
                    v-bind:class="{
                      'form-control': true,
                      'is-invalid':
                        !validLinkName(article.link_name) && bluredLinkName,
                    }"
                    v-on:blur="bluredLinkName = true"
                    v-model="article.link_name"
                    placeholder="your-interesting-blog-article"
                  />
                  <small
                    >https://talkii.app/blog/<b>{{
                      article.link_name != ""
                        ? article.link_name
                        : "your-interesting-blog-article"
                    }}</b></small
                  >
                  <div class="invalid-feedback">
                    Please enter a valid link name. Only letters and numbers
                    separated by '-'.
                  </div>
                </div>

                <div class="form-group">
                  <label for="category"
                    ><i class="fad fa-th-list"></i> Category</label
                  >
                  <div class="d-flex">
                    <b-form-select v-model="article.categoryId">
                      <option
                        v-for="(item, index) in categories"
                        v-bind:key="index"
                        :value="item.id"
                        >{{ item.name }}</option
                      >
                    </b-form-select>
                    <button
                      type="button"
                      class="btn btn-outline-primary ml-2"
                      @click="showAddCategoryDialog"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>

                <div class="form-group">
                  <label for="title"
                    ><i class="fad fa-clock"></i> Reading time in minutes</label
                  >
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="article.reading_time"
                  />
                  <small
                    >Display:
                    <b>{{
                      article.reading_time != "" ? article.reading_time : "0"
                    }}</b>
                    min read</small
                  >
                </div>

                <div class="form-group">
                  <label for="tags-basic"
                    ><i class="fad fa-tags"></i> Tags</label
                  >
                  <b-form-tags
                    input-id="tags-basic"
                    v-model="tagsArray"
                  ></b-form-tags>
                  <small>Type a new tag and press enter</small>
                </div>

                <div class="form-group">
                  <label for="title"
                    ><i class="fad fa-file-alt"></i> Short description</label
                  >
                  <input
                    type="text"
                    placeholder="coming soon"
                  />
                  <div class="invalid-feedback">
                    Please enter a short description for SEO.
                  </div>
                </div>
                <!----------------------- content end ----------------------->
              </b-card-body>
            </b-collapse>

            <!----------------------- 2 ----------------------->
            <div class="inner-jcard jtitle-w-btns mb-1">
              <h5
                @click="
                  showPictureInput = editMode
                    ? image
                      ? !showPictureInput
                      : false
                    : !showPictureInput
                "
                v-b-toggle.accordion-2
                class="articles-title"
              >
                2. Cover image
              </h5>
              <div class="side-btns">
                <h5 v-if="!validImage(image)" key="unchecked">
                  <i class="fal fa-exclamation-circle"></i>
                </h5>
                <h5 v-else class="text-blue" key="checked">
                  <i class="fa fa-check-circle"></i>
                </h5>
              </div>
            </div>
            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <!----------------------- content start ----------------------->
                <label for="title"
                  ><i class="fad fa-image"></i> Cover image</label
                >
                <div
                  v-if="editMode && !showPictureInput"
                  class="cover-image-preview-container"
                >
                  <div
                    class="cover-image-preview-bg"
                    :style="
                      'background-image: url(\'/api/blog/covers/' +
                        article.link_name +
                        '.png\')'
                    "
                  ></div>
                  <button @click="showPictureInput = true" class="jbtn jbtn-sm">
                    Change Picture
                  </button>
                </div>
                <!----------------------- content end ----------------------->
              </b-card-body>
            </b-collapse>

            <!-- picture input -->
            <picture-input
              v-if="showPictureInput"
              ref="pictureInput"
              @change="onImageChange"
              @remove="onImageRemove"
              :prefill="image"
              :width="530"
              :height="300"
              :removable="true"
              removeButtonClass="jbtn jbtn-sm jbtn-red"
              accept="image/jpeg, image/png, image/gif"
              buttonClass="jbtn jbtn-sm"
              :customStrings="{
                upload: '<h1>Upload it!</h1>',
                drag: '<small>Drag and drop cover image here</small>',
              }"
            >
            </picture-input>

            <div
              v-if="editMode && showPictureInput && !image"
              class="btn-container-center"
            >
              <button @click="showPictureInput = false" class="jbtn jbtn-sm">
                Cancel
              </button>
            </div>
            <!----------------------- 3 ----------------------->
            <div class="inner-jcard jtitle-w-btns mb-1">
              <h5
                @click="showPictureInput = false"
                v-b-toggle.accordion-3
                class="articles-title"
              >
                3. Text
              </h5>
              <div class="side-btns">
                <h5 v-if="!validInput(article.text)" key="uncheckedText">
                  <i class="fal fa-exclamation-circle"></i>
                </h5>
                <h5 v-else class="text-blue" key="checkedText">
                  <i class="fa fa-check-circle"></i>
                </h5>
              </div>
            </div>
            <b-collapse
              id="accordion-3"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <!----------------------- content start ----------------------->
                <!-- text editor -->
                <editor
                  v-model="article.text"
                  apiKey="hd33kq51ejn0iohlk1rdgqt02ap38a1op9umzttp0jsc3y9o"
                  :init="{
                    height: 600,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link charmap',
                      'searchreplace visualblocks fullscreen',
                      'print preview anchor insertdatetime',
                      'paste help wordcount table',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor| \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help',
                    block_formats:
                      'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Preformatted=pre',
                  }"
                >
                </editor>
                <!----------------------- content end ----------------------->
              </b-card-body>
            </b-collapse>
          </div>

          <div class="jrow-settings bottom">
            <label
              ><i class="fa fa-star text-orange"></i> Featured Cover<br /><small
                >Feature this article on the blog's home page as cover. 1
                max.</small
              ></label
            >
            <label class="switch">
              <input type="checkbox" v-model="article.featured" />
              <div>
                <span></span>
              </div>
            </label>
          </div>

          <div class="jrow-settings">
            <label
              ><i class="fa fa-star text-blue"></i> Featured Home<br /><small
                >Feature this article on the blog's home as 1 of 3 popular
                articles.</small
              ></label
            >
            <label class="switch">
              <input type="checkbox" v-model="article.featured_home" />
              <div>
                <span></span>
              </div>
            </label>
          </div>

          <div class="jrow-settings">
            <label
              ><i class="fad fa-check-circle"></i> Publish<br /><small
                >Save this article as a draft or publish it now.</small
              ></label
            >
            <label class="switch">
              <input type="checkbox" v-model="article.published" />
              <div>
                <span></span>
              </div>
            </label>
          </div>

          <b-alert v-if="showInputError" show variant="danger"
            ><i class="fad fa-exclamation-circle"></i>
            {{ validationMsg }}
          </b-alert>

          <!-- <div class="form-group">
            <label for="author"><i class="fad fa-user"></i> Author</label>
            <input type="text" class="form-control" v-model="article.author" />
          </div> -->

          <div class="btn-container text-center mt-5 mb-4">
            <button class="jbtn" @click="initSaveArticle">
              <i class="fa fa-save"></i> Save article
            </button>

            <!-- <button class="jbtn ml-2" @click="uploadImage">
              <i class="fa fa-save"></i> Save Image
            </button> -->
          </div>
        </div>
      </section>
    </div>

    <!-- ----- add category modal --------------------------------- -->
    <b-modal
      hide-header="true"
      hide-footer="true"
      id="add-category-modal"
      ref="add-category-modal"
      centered
      title="BootstrapVue"
    >
      <div class="jdialog-top">
        <div class="close-container">
          <h3><strong>Add blog category</strong></h3>
          <span
            @click="$bvModal.hide('add-category-modal')"
            class="ml-auto jclose"
            ><i class="fa fa-times "></i
          ></span>
        </div>
        <p>
          <small
            >Please specify a link name for this category. Remember all link
            names should be lower case, separated by a '-'</small
          >
        </p>
      </div>
      <div class="jdialog-main">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="name">
                <i class="fal fa-file-alt"></i> Category name
              </label>
              <input
                type="text"
                v-bind:class="{
                  'form-control': true,
                  'is-invalid':
                    !validInput(newCategory.name) && bluredNewCategory,
                }"
                v-on:blur="bluredNewCategory = true"
                v-model="newCategory.name"
              />
              <div class="invalid-feedback">
                Please enter something here.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="email"
                ><i class="fal fa-link"></i> Category link name</label
              >
              <input
                type="text"
                v-bind:class="{
                  'form-control': true,
                  'is-invalid':
                    !validLinkName(newCategory.link_name) &&
                    bluredCategoryLinkName,
                }"
                v-on:blur="bluredCategoryLinkName = true"
                v-model="newCategory.link_name"
              />
              <small
                >https://talkii.app/blog/category/<strong>{{
                  newCategory.link_name
                }}</strong></small
              >
              <div class="invalid-feedback">
                Please enter a valid link name. Only letters and numbers
                separated by '-'.
              </div>
            </div>
          </div>
        </div>

        <b-alert v-if="showDialogInputError" show variant="danger"
          ><i class="fad fa-exclamation-circle"></i>
          {{ dialogValidationMsg }}
        </b-alert>
      </div>
      <div class="jdialog-bottom with-cancel">
        <button
          @click="$bvModal.hide('add-category-modal')"
          class="jbtn jbtn-sm jbtn-red"
        >
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="saveBlogCategory" class="jbtn jbtn-sm">
          <span v-if="isSavingDialog" key="spinner"
            ><i class="fad fa-spinner-third fa-spin"></i
          ></span>
          <span v-else key="button"><i class="fa fa-plus"></i> Add</span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
//import C from "@/constants";
//import PLANS from "@/plans";
import axios from "axios";
import toast from "@/assets/js/toast";
import Editor from "@tinymce/tinymce-vue";
import PictureInput from "vue-picture-input";

export default {
  name: "AddEditArticle",
  components: {
    editor: Editor,
    PictureInput,
  },
  data() {
    return {
      editMode: false,
      changeImageMode: false,
      showInputError: false,
      showDialogInputError: false,
      validationMsg: "",
      dialogValidationMsg: false,
      showPictureInput: false,
      articleId: -1,
      image: null,
      tagsArray: [],
      article: {
        title: "",
        link_name: "",
        categoryId: 1,
        reading_time: 1,
        cover_url: "",
        text: "",
        description: "",
        tags: "",
        author: "",
        featured: false,
        featured_home: false,
        featured_category: false,
        published: false,
      },
      bluredTitle: false,
      bluredLinkName: false,
      categories: [
        { id: 1, name: "Aac" },
        { id: 2, name: "News" },
      ],
      newCategory: { name: "", link_name: "" },
      bluredNewCategory: false,
      bluredCategoryLinkName: false,

      isLoading: false,
      isSaving: false,
      isSavingDialog: false,
    };
  },
  methods: {
    init() {
      //load categories
      this.getCategories();
      //check for query
      if (this.$route.params.mode != null && this.$route.params.mode != "") {
        //check for edit mode
        if (this.$route.params.mode === "edit") {
          this.editMode = true;
          console.log("editMode: " + this.editMode);

          if (this.$route.query.id != null && this.$route.query.id != "") {
            this.articleId = this.$route.query.id;
            console.log("to edit id: " + this.articleId);
            //load article
            this.getBlogArticle(this.articleId);
          }
        }
      }
    },
    getCategories() {
      axios
        .post("/api/blog/functions-blog.php", {
          request: "get_categories",
        })
        .then((response) => {
          console.log(response.data);
          this.categories = response.data;
          if (!Array.isArray(this.categories) || !this.categories.length) {
            toast.error("Categories list is empty.");
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    },
    getBlogArticle(articleId) {
      axios
        .post("/api/blog/functions-blog.php", {
          request: "get_article",
          id: articleId,
        })
        .then((response) => {
          console.log(response.data);
          this.article = response.data;
          if (this.article != null && this.article != "") {
            this.tagsArray = this.article.tags.split("+");
            this.article.published = parseInt(this.article.published);
            this.article.featured = parseInt(this.article.featured);
            this.article.featured_home = parseInt(this.article.featured_home);
          } else {
            toast.error("Unable to load article. Article was not found.");
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Unable to load article. " + error.message);
        });
    },
    showAddCategoryDialog() {
      this.showDialogInputError = false;
      this.dialogValidationMsg = "";
      this.$refs["add-category-modal"].show();
    },
    saveBlogCategory() {
      if (!this.isSavingDialog && this.validateCategory()) {
        this.isSavingDialog = true;
        axios
          .post("/api/blog/functions-blog.php", {
            request: "add_category",
            name: this.newCategory.name,
            link_name: this.newCategory.link_name,
          })
          .then((response) => {
            //alert(response.data);
            console.log(response.data);
            //check if valid response, check status
            if (response.data === "OK") {
              toast.success("Category saved.");
              //reload categories
              this.getCategories();
              //reset vars
              this.newCategory = { id: 0, name: "", link_name: "" };
              //close modal
              this.$refs["add-category-modal"].hide();
            } else if (response.data === "LINK_NAME_EXISTS") {
              this.showDialogInputError = true;
              this.dialogValidationMsg =
                "This link name already exists. Please enter a different link name and try again.";
            } else {
              toast.error("Couldn't save category. " + response.data);
            }
            this.isSavingDialog = false;
          })
          .catch((error) => {
            console.error(error);
            toast.error("Couldn't save category. " + error.message);
            this.isSavingDialog = false;
          });
      }
    },
    initSaveArticle() {
      this.showInputError = false;
      this.validationMsg = "";
      //validate
      if (!this.isSaving && this.validateArticle()) {
        this.saveBlogArticle();
      } else {
        this.showInputError = true;
        this.validationMsg = "Please provide all necessary information.";
      }
    },
    saveBlogArticle() {
      console.log(this.article.text);
      //   let that = this;
      axios
        .post("/api/blog/functions-blog.php", {
          request: this.editMode ? "edit_article" : "add_article",
          id: this.editMode ? this.article.id : "0",
          title: this.article.title,
          categoryId: this.article.categoryId,
          link_name: this.article.link_name,
          reading_time: this.article.reading_time,
          cover_url: this.article.cover_url,
          text: this.article.text,
          description: this.article.description,
          tags: this.tagsToString(),
          author: this.article.author,
          featured: this.article.featured,
          featured_home: this.article.featured_home,
          published: this.article.published,
        })
        .then((response) => {
          //alert(response.data);
          console.log(response.data);
          //check if valid response, check status
          if (response.data === "OK") {
            if (!this.editMode || (this.editMode && this.image)) {
              this.uploadImage();
            } else {
              toast.success("Article saved.");
              this.$router.push({ name: "ArticlesList" });
            }
          } else if (response.data === "LINK_NAME_EXISTS") {
            this.showInputError = true;
            this.validationMsg =
              "This link name already exists. Please enter a different link name and try again.";
          } else {
            toast.error("Couldn't save article. " + response.data);
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          toast.error("Couldn't save article. " + error.message);
          this.isLoading = false;
        });
    },
    deleteBlogArticle() {
      console.log(this.article.text);
      //   let that = this;
      axios
        .post("/api/blog/functions-blog.php", {
          request: "delete_article",
          id: this.article.id,
        })
        .then((response) => {
          //alert(response.data);
          console.log(response.data);
          //check if valid response, check status
          if (response.data === "OK") {
            toast.success("Article deleted.");
          } else {
            toast.error("Couldn't delete article. " + response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Couldn't delete article. " + error.message);
        });
    },
    onImageChange() {
      console.log("New picture selected!");
      if (this.$refs.pictureInput.file) {
        this.image = this.$refs.pictureInput.file;
        console.log(this.image);
      } else {
        console.log("Old browser. No support for Filereader API");
      }
    },
    onImageRemove() {
      console.log("New picture selected!");
      this.image = null;
    },
    uploadImage() {
      var formData = new FormData();
      formData.append("file", this.image);
      formData.append("request", "upload_cover_image");
      formData.append("link_name", this.article.link_name);

      axios
        .post("/api/blog/upload.php", formData, {
          header: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //alert(response.data);
          console.log(response.data);
          //check if valid response, check status
          if (response.data === "OK") {
            toast.success("Article saved.");
          } else {
            toast.error("Couldn't save cover image. " + response.data);
          }
          this.$router.push({ name: "ArticlesList" });
        })
        .catch((error) => {
          console.error(error);
          toast.error("Couldn't save cover image. " + error.message);
          this.$router.push({ name: "ArticlesList" });
        });
    },
    tagsToString() {
      var tagString = "";
      this.tagsArray.forEach((tag) => {
        if (tagString != "") tagString += "+" + tag;
        else tagString += tag;
      });
      return tagString;
    },
    validateArticle() {
      this.bluredTitle = true;
      if (!this.validInput(this.article.title)) {
        return false;
      }

      this.bluredLinkName = true;
      if (!this.validLinkName(this.article.link_name)) {
        return false;
      }

      if (!this.validImage(this.image)) {
        return false;
      }

      if (!this.validInput(this.article.text)) {
        return false;
      }

      return true;
    },
    validateCategory() {
      this.bluredNewCategory = true;
      if (!this.validInput(this.newCategory.name)) {
        return false;
      }
      this.bluredCategoryLinkName = true;
      if (!this.validLinkName(this.newCategory.link_name)) {
        return false;
      }
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validLinkName(input) {
      var re = /[^a-zA-Z0-9-]/;
      return input.trim().length > 0 && !re.test(input.trim());
    },
    validTagsArray(input) {
      return Array.isArray(input) && input.length;
    },
    validImage(image) {
      return this.editMode ? true : image;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/blog.scss";
#add-edit-article .bottom {
  margin-top: 30px;
}
.picture-input {
  margin-bottom: 30px;
}
</style>
